// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;

$primary: #2163E8;

/* Light mode */
$systemGray: rgb(142,142,147);
$systemGray2: rgb(174, 174, 148);
$systemGray3: rgb(199, 199, 204);
$systemGray4: rgb(209, 209, 214);
$systemGray5: rgb(229, 229, 234);
$systemGray6: rgb(242, 242, 247);

/* Dark mode */
$dark-systemGray: rgb(142,142,147);
$dark-systemGray2: rgb(99, 99, 102);
$dark-systemGray3: rgb(72, 72, 74);
$dark-systemGray4: rgb(58, 58, 60);
$dark-systemGray5: rgb(44, 44, 46);
$dark-systemGray6: rgb(28, 28, 30);

$fontAwesomeIconWeight: 300;
$rounded-corner-app: 10px;

$modal-content-border-radius: $rounded-corner-app;

/* Theme: Light */
$input-bg: #fff;
$body: #f7f9f9;
$body-bg: $body;
$body-alt: #fff;
$body2: darken($body, 3%);
$body2: #fff;
$body3: desaturate(darken($body, 10%), 50%);
$body4: darken($body, 15%);
$body5: darken($body, 20%);
$body6: darken($body, 25%);
$opposite: #000; // se light mode: #000, se dark mode: #fff
$sec: darken($body, 27%);
$body-color: desaturate(darken($body, 76%), 10%);
$primary-soft: rgb(33, 99, 232);


/* Theme: Dark */
$dark-body: darken(#313C57, 20%);
$dark-body: desaturate($dark-body, 100%); /* desaturating for multi-theme purpose */
$dark-body-bg: $dark-body;
$dark-body-alt: lighten($dark-body, 2%);
$dark-body2: lighten($dark-body, 6%); /* was: 6 */
$dark-body3: lighten($dark-body, 10%);
$dark-body4: lighten($dark-body, 15%);
$dark-body5: lighten($dark-body, 20%);
$dark-body6: lighten($dark-body, 25%);
$dark-border-color: lighten($dark-body, 15%);
$dark-text-muted: desaturate(lighten($dark-body, 35%), 40%);
$dark-body-color: desaturate(lighten($dark-body, 65%), 10%);
$dark-opposite: #fff; // se light mode: #000, se dark mode: #fff
$dark-sec: lighten($dark-body, 27%);

$dark-modal-backdrop-opacity: .75;
$dark-dropdown-bg: $dark-body3;
$dark-placeholder-opacity-max:           .65;
$dark-placeholder-opacity-min:           .15;
$dark-offcanvas-bg-color: $dark-body2;
$dark-dropdown-bg-color: $dark-body2;
$dark-modal-content-bg: $dark-body2;
$dark-input-bg: $dark-body4;
$dark-form-select-bg: $dark-body4;
$dark-input-color: lighten($dark-body4, 60);
$dark-form-select-color: lighten($dark-body4, 60);
$dark-input-border-color: darken($dark-border-color, 15%);
$dark-form-select-indicator-color: $dark-input-color;
$dark-form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='rgb(195, 203, 221)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>") !default;
$dark-primary-soft: rgb(33, 99, 232);


/* Flat ui colors */
$red: #eb2f06;
$orange: #fa983a;
$yellow: #f7b731;
$green: #20bf6b;
$teal: #0fb9b1;
$blue: #3867d6;
$purple: #8854d0;
$cyan: #2d98da;

$blue: #2163E8;

/* Trying desaturating everything for multi-theme capabilities */
$desaturate_amount: 10%;
$dark-body: 			desaturate($dark-body, $desaturate_amount);
$dark-body-bg: 			$dark-body;

$dark-body: #000;
$dark-body-bg: #000;

$dark-body-alt: lighten($dark-body, 2%);
$dark-body2: lighten($dark-body, 9%);
$dark-body3: lighten($dark-body, 10%);
$dark-body4: lighten($dark-body, 15%);
$dark-body5: lighten($dark-body, 20%);
$dark-body6: lighten($dark-body, 25%);

$dark-border-color: lighten($dark-body, 15%);

$dark-body-alt: 		desaturate($dark-body-alt, $desaturate_amount);
$dark-body2: 			desaturate($dark-body2, $desaturate_amount);
$dark-body3: 			desaturate($dark-body3, $desaturate_amount);
$dark-body4: 			desaturate($dark-body4, $desaturate_amount);
$dark-body5: 			desaturate($dark-body5, $desaturate_amount);
$dark-body6: 			desaturate($dark-body6, $desaturate_amount);
$dark-border-color: 	desaturate($dark-border-color, $desaturate_amount);
$dark-text-muted: 		desaturate($dark-text-muted, $desaturate_amount);
$dark-body-color: 		desaturate($dark-body-color, $desaturate_amount);
$dark-sec: 				desaturate($dark-sec, 100%);




$dark-dropdown-bg: 						desaturate($dark-body3, $desaturate_amount);
$dark-offcanvas-bg-color: 				desaturate($dark-body2, $desaturate_amount);
$dark-dropdown-bg-color: 				desaturate($dark-body2, $desaturate_amount);
$dark-modal-content-bg: 				desaturate($dark-body2, $desaturate_amount);
$dark-input-bg: 						desaturate($dark-body4, $desaturate_amount);
$dark-form-select-bg: 					desaturate($dark-body4, $desaturate_amount);
$dark-input-color: 						lighten($dark-body4, 60);
$dark-form-select-color: 				lighten($dark-body4, 60);
$dark-input-border-color: 				darken($dark-border-color, 15%);
$dark-form-select-indicator-color: 		$dark-input-color;
