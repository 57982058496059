// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import '~floating-vue/dist/style.css';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import 'video.js/dist/video-js.css';
@import 'mixins';

#flashes-area .alert {
  margin-bottom:0px
}

#header-navigation #navbarSupportedContent .nav-link,
.tabbar-nav .nav-link {
max-width: 130px;
margin-left: auto;
margin-right: auto
}

.circular-progress {
	transform: rotate(-90deg);
    overflow: initial;
    transition: all 0.5s ease;

    circle {
	    transition: all 0.5s ease;
    }
}

.fw-medium {
	font-weight: 500;
}

* {
    scrollbar-width: auto;
    scrollbar-color: #c0c0c0 transparent;

    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c0c0c0;
        border-radius: 10px;
        border: 0px;
        cursor: pointer;
    }
}

#wapp-sidebar-area, #offcanvas-leftside, #offcanvas-teams {
	* {
		&::-webkit-scrollbar {
			display: none;
        }
	}

	&:hover {
		* {
			scrollbar-width: auto;
		}

		*::-webkit-scrollbar {
			display: block;
		}
	}


	.list-group {
		.list-group-item {
			&:not(.active) {
				color: $body-color;

				i.fas, i.fab, i.far, i.bi, .svg-inline--fa, .b5-fa-icon {
					color: rgba($body-color, 0.75)
				}
			}

			i.fas, i.fab, i.far, .svg-inline--fa, .b5-fa-icon {
				margin-top: ($spacer * .25);
				margin-bottom: ($spacer * .15);
			}
		}
	}
}


circle:nth-child(212312){
    stroke-dashoffset:calc(100 * 6);
    stroke-dasharray:calc(100 * 6);
    stroke-dashoffset:calc((100 * 6) - ((100 * 6) * 90) / 100);
    stroke-position: inside;
}

*::selection {
    background: $primary;
    color: #ffffff;
}
*::-moz-selection {
    background: $primary;
    color: #ffffff;
}
*::-webkit-selection {
    background: $primary;
    color: #ffffff;
}

.offcanvas-start.offcanvas-sm {
	width: 320px
}
.offcanvas-start.offcanvas-xs {
	width: 290px
}
.offcanvas-bottom.offcanvas-100 { height: 100vh; }
.offcanvas-bottom.offcanvas-75 { height: 75vh; }
.offcanvas-bottom.offcanvas-50 { height: 50vh; }

$vh_amounts: (
	30, 50, 75, 90
);

@each $amount in $vh_amounts {
	.min-vh-#{$amount} {
		min-height: #{$amount}vh!important;
	}
	.max-vh-#{$amount} {
		max-height: #{$amount}vh!important;
	}
}

.rounded {
	&-top-0 {
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
	}

	&-bottom-0 {
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	&-start-0 {
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}

	&-end-0 {
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	&-app {
		border-radius: $rounded-corner-app!important;
	}

	&-top-app {
		@include rounded-top-app;
	}

	&-bottom-app {
		@include rounded-bottom-app;
	}

	&-start-app {
		@include rounded-start-app;
	}

	&-end-app {
		@include rounded-end-app;
	}
}

/* rounded corner of app
@include media-breakpoint-up(xxl) {
	.main-area-container {
		border-top-left-radius: 15px;
		border: solid #EFF3F4;
		border-width: 1px 0px 0px 1px;
	}
}
*/

.post-elements {
    .item,
    .elements--post-category--alert,
    .elements--post---markdown-text,
    .elements--post---picture {
        padding-left: 0;
        padding-right: 0;
    }

    .elements--post---markdown-text {
        img {
            max-width: 100%;
            border-radius: $border-radius;
        }

        table {
            border: 1px solid $border-color;

            thead {
                background: red;
                color: white;
            }

            tfoot {
                background: blue;
                color: white;
            }

            tbody th {
                background: yellow;
                color: white;
            }

            tr, th, td {
                border: 1px solid $border-color;
            }

            th, td {
                padding: 0.25rem 0.5rem;
            }
        }
    }
}

.elements--post-category--banner {
	.card-img, .img-bg {
		@include rounded-top-app;
	}

	.with-bg-picture {

	}

	.with-main-picture {
		.card-body {
			@include rounded('0px');
			@include rounded-bottom-app;
		}
	}

	.without-main-picture {
		.main-col > div,
		.img-base {
			@include rounded-app;
		}

		.card-body {
			@include rounded-app;
		}
	}

	.without-bg-picture {

	}
}

.page-elements,
.post-category-elements {
    .elements--post-category--post-collection-smart {
        .b5-container-xl {
            padding-left: 0;
            padding-right: 0;
        }

        .heading, .row {
            padding-left:0.75rem;
        }

        .row {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.placeholder-area {
    .elements--post-category--post-collection-smart {
        .b5-container-xl {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@include media-breakpoint-up(lg) {
	.elements--post-category--banner {
		.card-img, .img-bg {
			@include rounded('0px');
			@include rounded-start-app;
		}

		.card-body {
			@include rounded('0px');
			@include rounded-end-app;
		}
	}

    .col-lg-4 {
        .elements--post-category--banner {
            .main-col, .col-lg-5 { /* Making banners columns full width on desktop - small column container */
                width: 100%;
                flex: auto;
            }
        }
    }
}

.elements--post---markdown-text {
	img, video, audio {
		max-width: 100%;
	}
}

.min-height-100 {
	min-height: 100%;
}


$overlays_intensity: (
	"sm": 0.3,
	"md": 0.5,
	"lg": 0.65,
	"xl": 0.75,
	"xxl": 0.95
);

.overlay {
	&-dark {
		background-color: rgba(0,0,0,0.3);

		@each $name, $intensity in $overlays_intensity {
			&.overlay-#{$name} {
				background-color: rgba(0,0,0, $intensity);
			}
		}
	}

	&-light {
		background-color: rgba(255,255,255,0.3);

		@each $name, $intensity in $overlays_intensity {
			&.overlay-#{$name} {
				background-color: rgba(255,255,255, $intensity);
			}
		}
	}
}

.border-evidence {
	border-color: darken($border-color, 35%)!important;
}

.circle-indicator {
	width: 16px;
	height: 8px;
}

.pre-formatted {
	white-space: pre; /* enable nl2br */
}

.bg-none {
	background-color: transparent!important;
}

.bg-cover {
	background-repeat: no-repeat!important;
	background-size: cover!important;
	background-position: center center!important;
}

.full-height-login-mobile {
	min-height: 90%;
}

@include media-breakpoint-up(lg) {
	.full-height-login-mobile {
		min-height: 100%;
	}
}

.b5 {
	&-transitionable {
		transition: 0.3s;
	}

	&-bg-opaque {
		-webkit-backdrop-filter: saturate(180%) blur(8px);
		backdrop-filter: saturate(180%) blur(8px);

		&-sm {
			-webkit-backdrop-filter: saturate(180%) blur(4px);
			backdrop-filter: saturate(180%) blur(4px);
		}

		&-lg {
			-webkit-backdrop-filter: saturate(180%) blur(20px);
			backdrop-filter: saturate(180%) blur(20px);
		}
	}
	&-bg-body { background-color: $body }
	&-bg-body-alt { background-color: $body-alt }
	&-bg-body2 { background-color: $body2 }
	&-bg-body3 { background-color: $body3 }
	&-bg-body4 { background-color: $body4 }
	&-bg-body5 { background-color: $body5 }
	&-bg-body6 { background-color: $body6 }

	&-bg-primary-soft {
		background-color: rgba($primary-soft, 0.2)!important;
	}

	&-bg {
		&-evidence {
			background-color: rgba($opposite, 0.1);
		}

		&-gradient-dark-opaque-bottom {
			background: rgb(0,0,0);
			background: linear-gradient(0deg, rgba(0,0,0,0.55) 0%, rgba(0,0,0,0.45) 50%, rgba(0,0,0,0) 100%);
		}

		&-gradient-body-transparent-bottom {
			background: rgb(0,0,0);
			background: linear-gradient(0deg, rgba($body, 1) 0%, rgba($body,0.75) 50%, rgba($body,0) 100%);
		}
	}

	&-container {
		@include make-container();

		&-sm {
			@include make-container();
			max-width: 768px;
		}

		&-md {
			@include make-container();
			max-width: 1080px;
		}

		&-lg {
			@include make-container();
			max-width: 1200px;
		}

		&-xl {
			@include make-container();
			max-width: 1440px;
		}

		&-xxl {
			@include make-container();
			max-width: 1800px;
		}
	}

	&-fa-icon {
		font-family: "Font Awesome 6 Pro";
		font-weight: $fontAwesomeIconWeight;

		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		display: var(--fa-display,inline-block);
		font-style: normal;
		font-variant: normal;
		line-height: 1;
		text-rendering: auto;
	}
}

#wapp-mainzz {
	@extend .mw-100;
}

@include media-breakpoint-up(xxl) {
	#wapp-mainzz {
		max-width: calc(100% - 300px)!important;
	}
}

#wapp-sidebar-area {
	display: none;
	width: 300px;
	z-index: 2;
}

#wapp-sidebar-content {
	width: 300px;
	z-index: 10;
}

@media (min-width: 1400px) {
	#wapp-sidebar-area {
		display: block;
	}
}

$b5_columns: (
	"2": 93%,
	"3": 47%,
	"4": 28%,
	"4-alt": 32%,
	"5": 24%,
	"6": 18%,
);

.b5-col {
	@include make-col-ready();

	@each $name, $size in $b5_columns {
		&-#{$name} {
			flex: 0 0 auto;
			width: #{$size};
		}
	}
}

@include media-breakpoint-up(sm) {
	@each $name, $size in $b5_columns {
		.b5-col-sm-#{$name} {
			flex: 0 0 auto;
			width: #{$size};
		}
	}
}
@include media-breakpoint-up(md) {
	@each $name, $size in $b5_columns {
		.b5-col-md-#{$name} {
			flex: 0 0 auto;
			width: #{$size};
		}
	}
}
@include media-breakpoint-up(lg) {
	@each $name, $size in $b5_columns {
		.b5-col-lg-#{$name} {
			flex: 0 0 auto;
			width: #{$size};
		}
	}
}
@include media-breakpoint-up(xl) {
	@each $name, $size in $b5_columns {
		.b5-col-xl-#{$name} {
			flex: 0 0 auto;
			width: #{$size};
		}
	}
}
@include media-breakpoint-up(xxl) {
	@each $name, $size in $b5_columns {
		.b5-col-xxl-#{$name} {
			flex: 0 0 auto;
			width: #{$size};
		}
	}
}



.navigation-item-with-icon {
	&.active {
		.b5-fa-icon {
			font-weight: 900;
		}
	}
}

.bg-black {
	background-color: #000
}

.bg-teal { background-color: $teal }
.bg-indigo { background-color: $indigo }
.bg-pink { background-color: $pink }
.bg-purple { background-color: $purple }
.bg-orange { background-color: $orange }
.bg-cyan { background-color: $cyan }

.avatar {
	width: 32px;
	height: 32px;

	span {
		font-size: 0.65rem;
	}

	&-xs {
		width: 18px;
		height: 18px;
	}

	&-sm {
		width: 24px;
		height: 24px;
	}

	&-md {
		width: 48px;
		height: 48px;

		span {
			font-size: 1rem;
		}
	}

	&-lg {
		width: 94px;
		height: 94px;

		span {
			font-size: 1.9rem
		}
	}

	&-xl {
		width: 128px;
		height: 128px;

		span {
			font-size: 2.9rem
		}
	}

	&-xxl {
		width: 256px;
		height: 256px;

		span {
			font-size: 5.9rem
		}
	}

	&--placeholder {
		background: url('/assets/img/default-avatar/default.jpg') no-repeat center center;
		background-size: cover;
	}
}

.bg-gradient {
	background-image: none;
}

.bg-white {
	background-color: #fff;
}

.card {
	background-color: $body2;

	.card {
		background-color: $body;

		.card {
			background-color: $body-alt;
		}
	}
}

.bg-light {
	background-color: $body3!important;
}

*, html, body, a, p {
	font-family: 'Montserrat', sans-serif;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-weight: normal;
}

h1, h2 {
	font-family: 'Playfair Display', serif;
	font-weight: bold
}

h5 {
	font-weight: 600;
	font-size: 1.15rem
}

/* VIDEO JS */
.vjs_video_3-dimensions.vjs-fluid,
.vjs-fluid {
    padding-top: 0!important;
}
.video-js.vjs-fluid,
.video-js.vjs-16-9,
.video-js.vjs-4-3,
.video-js.vjs-9-16,
.video-js.vjs-1-1 {
    height: auto!important;
}
.video-js {
    background-color: none!important;
    background-color: transparent!important;
    background-color: rgba(0,0,0,0)!important;

    .vjs-tech {
        position: relative;
    }

    .vjs-menu-button-popup .vjs-menu {
        z-index:9
    }

    .vjs-big-play-button {
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%)!important;
        @extend .b5-bg-opaque;
        @extend .btn-opaque-dark;
        color: #fff;
        border: 0px;
    }

    .vjs-custom-control-spacer {
          display:block!important;
          width:100%;
      }

    .vjs-progress-control {
        bottom: 2.5rem!important;
        width: auto;
        position: absolute;
        left: 0px;
        right: 0px;
        color: #fff;
        background: transparent;
        backdrop-filter: none;

        .vjs-progress-holder {
            margin: 0!important;
        }

        .vjs-load-progress,
        .vjs-progress-holder {
            @extend .overlay-dark;
            @extend .overlay-lg;
        }
    }
    .vjs-play-progress {
        background: $primary!important;

        &::before {
            font-size: 1.1rem!important;
            top: -0.25rem!important;
        }
    }
    .vjs-progress-holder {
        height: 0.5rem!important
    }

    .vjs-control-bar {
        height: 2.5rem;
        @extend .overlay-dark;
        @extend .overlay-lg;

        &:hover {
            @extend .overlay-dark;
            @extend .overlay-lg;
        }
    }
}

.form-control {
	background-color: $input-bg!important;
	border: $input-border-width solid $input-border-color!important;
}


.navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
/*
.navbar-nav {
	.nav-link {
		color: #A09FA4
	}
}
*/
/*
.form-search-area {
	.form-control:not(.disabled),
	.form-control:not(:disabled),
	.btn {
		background-color: $body3;
		border: 0;
		color: #A09FA4;

		&:hover, &:focus {
			background-color: $body4;
		}
	}
}
*/



.list-transparent {
	.list-group-item:not(.active):not(:hover) {
		background-color: transparent;
	}
}

/*
.b5-list-group-item-alt {
	background: none;

	&:hover, &.active {
		background: $body3;
	}
}
*/

.switch {
	position: relative;
	display: inline-block;
	width: 38px;
	height: 22px;

	/* Hide default HTML checkbox */
	input {
		display:none;

		&:focus + .slider {
			box-shadow: 0 0 1px #2196F3;
		}

		&:checked + .slider:before {
			-webkit-transform: translateX(16px);
			-ms-transform: translateX(16px);
			transform: translateX(16px);
		}
	}

	/* The slider */
	.slider {
		background-color: $body5;
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		-webkit-transition: .4s;
		transition: .4s;
		width: 38px;
		height: 22px;

		&:before {
			position: absolute;
			content: "";
			height: 14px;
			width: 14px;
			left: 4px;
			bottom: 4px;
			background-color: #fff;
			-webkit-transition: .4s;
			transition: .4s;
		}

		&.round {
			border-radius: 34px;

			&:before {
				border-radius: 50%;
			}
		}
	}

	&-lg {
		width: 60px;
		height: 34px;

		.slider {
			&:before {
				width: 26px;
				height: 26px;
				left: 4px;
				bottom: 4px;
			}
		}

		input:checked + .slider:before {
			-webkit-transform: translateX(26px);
			-ms-transform: translateX(26px);
			transform: translateX(26px);
		}
	}

	input.default:checked + .slider {
	  background-color: #444;
	}
	input.primary:checked + .slider {
	  background-color: $primary;
	}
	input.success:checked + .slider {
	  background-color: $green;
	}
	input.info:checked + .slider {
	  background-color: #3de0f5;
	}
	input.warning:checked + .slider {
	  background-color: $yellow;
	}
	input.danger:checked + .slider {
	  background-color: $red;
	}
}

.carousel-control-next, .carousel-control-prev {
    width: 10%
}

.carousel-indicators {
    margin-bottom: 0.5rem;
}

.carousel-indicators [data-bs-target] {
	width: 8px;
	height: 8px;
	@include rounded(50%);

    &.active {
        width: 16px;
        border-radius: 8px!important;
    }
}

.carousel-scheme {
	&-light {
		.carousel-control-prev-icon,
		.carousel-control-next-icon {
			filter: none;
		}

		.carousel-indicators [data-bs-target] {
			background-color: #fff;
		}

		.carousel-caption {
			color: #fff;
		}
	}

	&-dark {
		.carousel-control-prev-icon,
		.carousel-control-next-icon {
			filter: $btn-close-white-filter;
		}

		.carousel-indicators [data-bs-target] {
			background-color: #000;
		}

		.carousel-caption {
			color: #000;
		}
	}

	&-auto {
		.carousel-control-prev-icon,
		.carousel-control-next-icon {
			filter: $btn-close-white-filter;
		}

		.carousel-indicators [data-bs-target] {
			background-color: #000;
		}

		.carousel-caption {
			color: #000;
		}
	}

	&-opposite {
		.carousel-control-prev-icon,
		.carousel-control-next-icon {
			filter: none;
		}

		.carousel-indicators [data-bs-target] {
			background-color: #fff;
		}

		.carousel-caption {
			color: #fff;
		}
	}
}

.btn {
	font-weight: 600;

	&-white {
		background-color: #fff;
		border-color: #fff;
		color: #000;

		&:hover {
			background: #000;
			border-color: #000;
			color: #fff;
		}
	}

	&-light {
		background-color: $body5;
		border-color: $body5;
		color: $systemGray;

		&:hover {
			background-color: $body6;
			border-color: $body6;
			color: $systemGray;
		}
	}

	&-opaque {
		color: #fff;
		background-color: rgba(0, 0, 0, 0.3);
		border-color: rgba(0, 0, 0, 0);

		&-light {
			color: #fff;
			background-color: rgba(255,255,255,0.5);
			border-color: rgba(255,255,255,0);

			&:hover {
				background-color: #fff;
				border-color: #fff;
				color: #000;
			}

			&-xs {
				color: #fff;
				background-color: rgba(255,255,255,0.15);
				border-color: rgba(255,255,255,0);

				&:hover {
					background-color: #fff;
					border-color: #fff;
					color: #000;
				}
			}

			&-sm {
				color: #fff;
				background-color: rgba(255,255,255,0.3);
				border-color: rgba(255,255,255,0);

				&:hover {
					background-color: #fff;
					border-color: #fff;
					color: #000;
				}
			}

			&-lg {
				color: #000;
				background-color: rgba(255,255,255,0.85);
				border-color: rgba(255,255,255,0);

				&:hover {
					background-color: #fff;
					border-color: #fff;
					color: #000;
				}
			}
		}

		&-dark {
			color: #fff;
			background-color: rgba(0, 0, 0, 0.5);
			border-color: rgba(0, 0, 0, 0);

			&:hover {
				background-color: #000;
				border-color: #000;
				color: #fff;
			}

			&-xs {
				color: #000;
				background-color: rgba(0, 0, 0, 0.15);
				border-color: rgba(0, 0, 0, 0);

				&:hover {
					background-color: #000;
					border-color: #000;
					color: #fff;
				}
			}

			&-sm {
				color: #000;
				background-color: rgba(0, 0, 0, 0.3);
				border-color: rgba(0, 0, 0, 0);

				&:hover {
					background-color: #000;
					border-color: #000;
					color: #fff;
				}
			}

			&-lg {
				color: #fff;
				background-color: rgba(0, 0, 0, 0.85);
				border-color: rgba(0, 0, 0, 0);

				&:hover {
					background-color: #000;
					border-color: #000;
					color: #fff;
				}
			}
		}
	}

	&-close {
		filter: none;
	}

	&-close-white {
		filter: $btn-close-white-filter;
	}

	&-primary, &-success, &-warning, &-danger {
		color: #fff;
	}

	&-apple-light {
		color: #000;
		background: #fff;
		border-color: #dedede;

		&:hover {
			background: #000;
			color: #fff;
			border-color: #111111;
		}
	}

	&-apple-dark {
		color: #fff;
		background: #000;
		border-color: #000;

		&:hover {
			background: #fff;
			color: #000;
			border-color: #fff;
		}
	}

	&-facebook {
		color: #fff;
		background: #3b5998;
		border-color: #3b5998;

		&:hover {
			background: #4267B2;
			border-color: #4267B2
		}
	}

	&-outline {
		color: #000;
		border-color: #000;

		&-apple-light {
			color: #fff;
			background: transparent;
			border-color: #fff;

			&:hover {
				background: #fff;
				color: #000;
				border-color: #fff;
			}
		}

		&-apple-dark {
			color: #000;
			background: transparent;
			border-color: #000;

			&:hover {
				background: #000;
				color: #fff;
				border-color: #000;
			}
		}

		&-facebook {
			color: #3b5998;
			background: transparent;
			border-color: #3b5998;

			&:hover {
				background: #3b5998;
				color: #fff;
			}
		}

		&-light {
			background: transparent;
			border-color: #fff;
			color: #fff;

			&:hover {
				color: #000;
				background-color: #fff;
			}
		}

		&-dark {
			background: transparent;
			border-color: #000;
			color: #000;

			&:hover {
				background: #000;
				color: #fff;
			}
		}
	}

	&-ghost {
		color: inherit;
		font-weight: 700;
		border-radius: $btn-border-radius;
		background-color: transparent;
		border-width: 0px;

		&:hover, &:active, &:focus {
			color: inherit;
			background-color: rgba($opposite, 0.1);
		}

		&.active {
			color: $opposite;
			background-color: rgba($opposite, 0.05);
		}
	}

	&-evidence {
		color: inherit;
		font-weight: 700;
		border-radius: $btn-border-radius;
		background-color: rgba($opposite, 0.1);
		border-width: 0px;

		&:hover, &:active, &:focus {
			color: inherit;
			background-color: rgba($opposite, 0.2);
		}

		&.active {
			color: $opposite;
			background-color: rgba($opposite, 0.15);
		}
	}

	&-post-reaction {
		transition: font-size 0.5s;
		padding: 0.1rem $btn-padding-x;
		font-size: 1.1rem;
	}

}


i.fa, i.bi, i.emoji {
	font-style: normal
}


.alert-container {
	@extend .container-xxl;
}

.container .alert .alert-container,
.container-xl .alert .alert-container,
.container-b5-sm .alert .alert-container {
	max-width: 100%;
	padding: 0;
}

.alert {
	border-width: 0px;

	&-info {
		background-color: #EAEFFD;
	}
}

.top-auto {
	top: auto!important;
}

.post-card {
	.card {
		@include rounded-app;
		@extend .border-0;
	}

	.card.card-raw {
		background-color: transparent;

		.card-img {
			@extend .card-img-top;
			@include rounded-app;
		}

		.card-body {
			@extend .px-0;
		}
	}

	.card.card-boxed {

		&:hover {
			@extend .shadow-lg;
			transform: scale(1.03);
		}

		.card-img {
			@extend .card-img-top;
			@include rounded-top-app;
		}

		.card-body {
			@include rounded-bottom-app;
		}
	}

	.card.card-boxed-invert {

		&:hover {
			@extend .shadow-lg;
			transform: scale(1.03);
		}

		.card-img {
			@extend .card-img-bottom;
			@include rounded-bottom-app;
		}

		.card-body {
			@include rounded-top-app;
		}
	}

	.card.card-overlay,
	.card.card-overlay-invert {

		&:hover {
			@extend .shadow-lg;
			transform: scale(1.03);
		}

		.card-img,
		.card-body {
			@include rounded-app;
		}
	}

	.card.card-modern {

		&:hover {
			@extend .shadow-lg;
			transform: scale(1.03);
		}

		.card-img {
			@include rounded-app;
		}

		.card-body {
			@include rounded-bottom-app;
		}
	}
}


.b5-hover-scale {
	&:hover {
		@extend .shadow-lg;
		transform: scale(1.03);
	}
}


.autosuggest-component {
	.b5-autosuggest-container {
		display: none;
		z-index: 2000;

		&:focus, &:active, &:hover {
			display: block;
		}
	}

	.b5-autosuggest-list {
		z-index: 2010;
	}

	&:focus-within .b5-autosuggest-container {
		display: block;
	}

	.autosuggest-input:focus + .b5-autosuggest-container,
	.autosuggest-input:active + .b5-autosuggest-container,
	.autosuggest-input:not(:focus) + .b5-autosuggest-container:focus,
	.autosuggest-input:not(:focus) + .b5-autosuggest-container:active,
	.autosuggest-input:not(:focus) + .b5-autosuggest-container:hover,

	&:has(> .autosuggest-input:focus) .b5-autosuggest-container,
	&:has(> .autosuggest-input:active) .b5-autosuggest-container,
	&:has(> .autosuggest-input:hover) .b5-autosuggest-container {
		display: block;
	}
}

.emoji-invoker {
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
	border-radius: 50%;
	cursor: pointer;
	transition: all 0.2s;

	&:hover {
		transform: scale(1.1);
	}
}

.emoji-invoker > svg {
  fill: #b1c6d0;
}

.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}

.emoji-picker {
	position: absolute;
	z-index: 1;
	border: 1px solid $border-color;
	width: 100%;
	max-width: 60%;
	height: 20rem;
	overflow-y: auto;
	padding: 1rem;
	box-sizing: border-box;
	border-radius: 0.5rem;
	background: $body2;
	box-shadow: 1px 3px 10px rgba(0,0,0,0.2);

	h5 {
		margin-bottom: 0;
		text-transform: uppercase;
		font-size: 0.8rem;
		cursor: default;
	}

	.emojis {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		&:after {
			content: "";
			flex: auto;
		}

		span {
			padding: 0.4rem;
			cursor: pointer;
			border-radius: 5px;
			font-size: 1.45rem;

			&:hover {
				background: $body3;
				cursor: pointer;
			}
		}
	}
}


.modal {
	-webkit-backdrop-filter: saturate(120%) blur(8px);
	backdrop-filter: saturate(120%) blur(8px);
}

.text-opposite {
	color:  $opposite!important;

	&-75 {
		color: rgba($opposite, .75)!important;
	}
	&-50 {
		color: rgba($opposite, .5)!important;
	}
	&-25 {
		color: rgba($opposite, .25)!important;
	}
}

.text-dynamic {
	&-light {
		color: rgba(255,255,255,0.8);
	}

	&-dark {
		color: rgba(0,0,0,0.8);
	}
}

.dropdown-menu {
	box-shadow: 1px 3px 10px rgba(0,0,0,0.2);
}

#header-navigation {
	.dropdown-menu {
		a.dropdown-item {
			color: inherit
		}
	}
}

.header-navbar,
#persistent-player,
.tabbar {
	background: $body-alt;
	backdrop-filter: blur(2px);
	background: rgba($body-alt, 0.95);

	background: rgba($body-alt, 0.8);
	-webkit-backdrop-filter: saturate(180%) blur(20px);
	backdrop-filter: saturate(180%) blur(20px);

	.nav-link {
		color: inherit;
	}
}


/* PART 1 - Before Lazy Load */
img[data-lazyloaded]{
	opacity: 0;
}

/* PART 2 - Upon Lazy Load */
img.litespeed-loaded{
	-webkit-transition: opacity .5s linear 0.2s;
	-moz-transition: opacity .5s linear 0.2s;
	transition: opacity .5s linear 0.2s;
	opacity: 1;
}


$themes: (
    "blue": "33, 99, 232",
    "cyan": "50,173,230",
    "teal": "48,176,199",
    "mint": "0,199,190",
    "green": "52,199,89",
    "forest": "12, 135, 104",
    "golden": "207,170,73",
    "orange": "255,149,0",
    "aorange": "255, 82, 54",
    "ared": "250, 36, 60",
    "pink": "232,67,147",
    "apink": "230, 53, 206",
    "apurple": "114, 49, 198",
    "indigo": "88,86,214",
    "brown": "162,132,94",
);

.b5-theme-default-text {
    color: $blue!important;
}

.b5-theme-default-bg {
    background-color: $blue!important;
}

.b5-theme-default-border {
    border-color: $blue!important;
}

@each $name, $theme_accent in $themes {
    .b5-theme-#{$name}-text {
        color: unquote("rgb(#{$theme_accent})")!important;
    }

    .b5-theme-#{$name}-bg {
        background-color: unquote("rgb(#{$theme_accent})")!important;
    }

    .b5-theme-#{$name}-border {
        border-color: unquote("rgb(#{$theme_accent})")!important;
    }

    .b5-theme-#{$name}-bg-soft {
        background-color: unquote("rgb(#{$theme_accent}, 0.2)")!important;
    }
}


@include media-breakpoint-down(sm) {
	.magnetic-scroll-on-mobile {
		scroll-snap-type: mandatory;
		scroll-snap-type: x mandatory;

		.magnetic-column {
			scroll-snap-align: center;
		}
	}
}

/* Dark mode */
html[data-theme='dark'], .theme--dark {
	:root {
		--bs-body-color: $dark-body-color;
	}

    * {
        scrollbar-color: $dark-body4 transparent;

        &::-webkit-scrollbar-thumb {
            background-color: $dark-body2;
            border: 1px solid $dark-body6;
        }
    }

	html, body {
		background-color: $dark-body-bg!important;
		color: $dark-body-color!important;
	}

	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
	}

    .toast {
        background: $dark-body3;
        color: $dark-body-color;
        border: 1px solid $dark-border-color;
    }

	h1, h2, h3,
	.h1, .h2, .h3 {
		color: #fff
	}

	.modal-backdrop.show {
		opacity: $dark-modal-backdrop-opacity;
	}

	.text-opposite {
		color:  $dark-opposite!important;

		&-75 {
			color: rgba($dark-opposite, .75)!important;
		}
		&-50 {
			color: rgba($dark-opposite, .5)!important;
		}
		&-25 {
			color: rgba($dark-opposite, .25)!important;
		}
	}

	.form-control {
		background-color: $dark-input-bg!important;
		border-color: $dark-input-border-color!important;
		color: $dark-input-color!important;
	}

	.form-select {
		background-color: $dark-form-select-bg!important;
		border-color: $dark-input-border-color!important;
		color: $dark-form-select-color!important;
		background-image: $dark-form-select-indicator!important;
	}

	.dropdown-divider {
		border-top-color: rgba($dark-opposite, 0.25);
	}

	.dropdown-item:hover, .dropdown-item:focus {
		background-color: $dark-body4;
		color: $dark-opposite;
	}

	.carousel-scheme {
		&-auto {
			.carousel-control-prev-icon,
			.carousel-control-next-icon {
				filter: none;
			}

			.carousel-indicators [data-bs-target] {
				background-color: #fff;
			}

			.carousel-caption {
				color: #fff;
			}
		}

		&-opposite {
			.carousel-control-prev-icon,
			.carousel-control-next-icon {
				filter: $btn-close-white-filter;
			}

			.carousel-indicators [data-bs-target] {
				background-color: $opposite;
			}

			.carousel-caption {
				color: #000;
			}
		}
	}

	.b5 {
		&-bg-body { background-color: $dark-body }
		&-bg-body-alt { background-color: $dark-body-alt }
		&-bg-body2 { background-color: $dark-body2 }
		&-bg-body3 { background-color: $dark-body3 }
		&-bg-body4 { background-color: $dark-body4 }
		&-bg-body5 { background-color: $dark-body5 }
		&-bg-body6 { background-color: $dark-body6 }

		&-bg-primary-soft {
			background-color: rgba($dark-primary-soft, 0.2)!important;
		}

		&-bg {
			&-evidence {
				background-color: rgba($dark-opposite, 0.1);
			}

			&-gradient-body-transparent-bottom {
				background: rgb(0,0,0);
				background: linear-gradient(0deg, rgba($dark-body, 1) 0%, rgba($dark-body, 0.75) 50%, rgba($dark-body,0) 100%);
			}
		}
	}

	.header-navbar,
	#persistent-player,
	.tabbar {
		background: $dark-body-alt;
		backdrop-filter: blur(2px);
		background: rgba($dark-body-alt, 0.95);

		background: rgba($dark-body-alt, 0.8);
		-webkit-backdrop-filter: saturate(180%) blur(20px);
		backdrop-filter: saturate(180%) blur(20px);
	}

	/* rounded corner of app
	.main-area-container {
		border-color: $dark-border-color;
	}
	*/

	#wapp-sidebar-area, #offcanvas-leftside, #offcanvas-teams {
		* {
			scrollbar-width: auto;
			scrollbar-color: $dark-body4 transparent;

			&::-webkit-scrollbar {
				display: none;
			}

			&::-webkit-scrollbar-track {
				background: transparent;
			}

			&::-webkit-scrollbar-thumb {
				background-color: $dark-body2;
				border: 1px solid $dark-body6;
			}
		}

		&:hover {
			* {
				scrollbar-width: auto;
			}

			*::-webkit-scrollbar {
				display: block;
			}
		}

		.card-header {
			background-color: $dark-body!important;
		}

		.list-group {
			.list-group-item:not(.active) {
				color: inherit;

				i.fas, i.fab, i.far, i.bi, .svg-inline--fa, .b5-fa-icon {
					color: inherit
				}
			}
		}
	}

	.progress {
		background-color: $dark-body6;
	}

	.border {
		border-color: $dark-border-color!important;

		&-start, &-top, &-bottom, &-end {
			border-color: $dark-border-color!important;
		}

		&-evidence {
			border-color: lighten($dark-border-color, 25%)!important;
		}

		&-primary {
			border-color: $primary!important;
		}

		&-success {
			border-color: $success!important;
		}

		&-secondary {
			border-color: $secondary!important;
		}

		&-warning {
			border-color: $warning!important;
		}

		&-danger {
			border-color: $danger!important;
		}

		&-info {
			border-color: $info!important;
		}
	}

	.offcanvas {
		background-color: $dark-offcanvas-bg-color;
	}

	.modal {
		&-header, &-footer {
			border-color: $dark-border-color;
		}

		&-content {
			background-color: $dark-modal-content-bg;
		}
	}

	.dropdown-menu {
		background-color: $dark-dropdown-bg-color;
		border-color: rgba($dark-opposite, 0.1);
		color: lighten($dark-body-color, 0.3);
	}

	.post-elements {
		.elements--post---markdown-text {
			table {
				border: 1px solid $dark-border-color;
			}

			tr, th, td {
				border: 1px solid $dark-border-color;
			}
		}
	}

	.list-group-item {
		&:not(.active) {
			background-color: $dark-body2;
			color: inherit;
		}

		&:hover:not(.active) {
			background-color: $dark-body3;
			color: $dark-opposite;
		}

		&.disabled, &:disabled {
			background-color: $dark-body-alt;
		}
	}

	.emoji-picker {
		background-color: $dark-body3;
		border-color: $dark-border-color;

		.emojis {
			span:hover {
				background-color: $dark-body4;
			}
		}
	}

	.switch {
		.slider {
			background-color: $dark-body5;
		}
	}

	.bg-white {
		background-color: $dark-body2;
	}

	.card {
		background-color: $dark-body2;
		border-color: $dark-border-color;

		.card {
			background-color: $dark-body3;

			.card {
				background-color: $dark-body2;
			}
		}
	}

	.bg-light {
		background-color: $body3!important;
	}

	.btn {
		color: $dark-systemGray;
		/* da tenere?
		&-light {
			background-color: $dark-body5;
			border-color: $dark-body5;
			color: $systemGray;

			&:hover {
				background-color: $dark-body6;
				border-color: $dark-body6;
				color: $systemGray;
			}
		}
		*/

		&-apple-light {
			color: #000;
		}

		&-primary, &-secondary, &-success, &-danger, &-warning, &-info, &-facebook, &-apple-dark {
			color: #fff;
		}
		&-outline {
			color: #fff;
			border-color: #fff;

			&-primary {
				color: $primary;

				&:hover {
					color: #fff;
					background-color: $primary;
					border-color: $primary;
				}
			}

			&-light {
				color: #fff;

				&:hover {
					color: #000!important;
				}
			}

			&-dark {
				color: #000;

				&:hover {
					color: #fff;
				}
			}

			&-success {
				color: $success;

				&:hover {
					color: #fff;
				}
			}
			&-danger {
				color: $danger;

				&:hover {
					color: #fff;
				}
			}
			&-warning {
				color: $warning;

				&:hover {
					color: #fff;
				}
			}
			&-info {
				color: $info;

				&:hover {
					color: #fff;
				}
			}
			&-secondary {
				color: lighten($dark-sec, 10%);
				border-color: lighten($dark-sec, 10%);

				&:hover {
					background-color: $dark-sec;
					color: #fff;
				}
			}
		}

		&-link {
			color: $primary;
		}

		&-opaque {
			color: #fff;
			background-color: rgba(255,255,255,0.3);
			border-color: rgba(255,255,255,0);

			&-light {
				color: #fff;

				&:hover {
					color: #000;
				}

				&-xs {
					color: #fff;

					&:hover {
						color: #000;
					}
				}

				&-sm {
					color: #fff;

					&:hover {
						color: #000;
					}
				}

				&-lg {
					color: #000;

					&:hover {
						color: #000;
					}
				}
			}

			&-dark {
				color: #000;

				&:hover {
					color: #fff;
				}

				&-xs {
					color: #000;

					&:hover {
						color: #fff;
					}
				}

				&-sm {
					color: #000;

					&:hover {
						color: #fff;
					}
				}

				&-lg {
					color: #fff;

					&:hover {
						color: #fff;
					}
				}
			}
		}

		&-close {
			filter: $btn-close-white-filter;
		}

		&-close-white {
			filter: none;
		}

		&-secondary {
			background: $dark-sec;
			color: #fff;
			border-color: $dark-sec;
		}

		&-ghost {
			&:hover, &:active, &:focus {
				background-color: rgba($dark-opposite, 0.1);
			}

			&.active {
				color: $dark-opposite;
				background-color: rgba($dark-opposite, 0.05);
			}
		}

		&-evidence {
			background-color: rgba($dark-opposite, 0.05);
			color: $dark-opposite!important;

			&:hover, &:active, &:focus {
				color: $dark-opposite!important;
				background-color: rgba($dark-opposite, 0.15);
			}

			&.active {
				color: $dark-opposite!important;
				background-color: rgba($dark-opposite, 0.1);
			}
		}
	}
}
