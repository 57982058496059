// Mixins & utilities
@mixin rounded-app {
	@include rounded-top-app;
	@include rounded-start-app;
	@include rounded-end-app;
	@include rounded-bottom-app;
}

@mixin rounded($amount) {
	border-radius: #{$amount};
}

@mixin rounded-top-app {
	border-top-left-radius: $rounded-corner-app;
	border-top-right-radius: $rounded-corner-app;
}

@mixin rounded-start-app {
	border-top-left-radius: $rounded-corner-app;
	border-bottom-left-radius: $rounded-corner-app;
}

@mixin rounded-end-app {
	border-top-right-radius: $rounded-corner-app;
	border-bottom-right-radius: $rounded-corner-app;
}

@mixin rounded-bottom-app {
	border-bottom-left-radius: $rounded-corner-app;
	border-bottom-right-radius: $rounded-corner-app;
}